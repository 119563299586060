import React, { Component } from "react";

import styles from "./style.module.scss";

import Title from "../Title";
import DropdownSection from "../DropdownSection";
import { TweenLite, TimelineLite } from "gsap";

import pdf1 from "./gdpr-partneri.pdf";
import pdf2 from "./gdpr-reality.pdf";
import pdf3 from "./gdpr-consulting.pdf";
import pdf4 from "./gdpr-financial.pdf";

import pdf5 from "./vlpartneri.pdf";
import pdf6 from "./vlreality.pdf";
import pdf7 from "./vlconsultingoffice.pdf";
import pdf8 from "./financialoffice.pdf";

import m3logo from "../../ui-admin/AdminHeader/assets/m3logo.svg";

class Footer extends Component {
    state = {};

    gdprRef = React.createRef();
    infoRef = React.createRef();
    leftLineRef1 = React.createRef();
    leftLineRef2 = React.createRef();
    rightLineRef1 = React.createRef();
    rightLineRef2 = React.createRef();

    toggleInfo = (infoRef, leftLineRef, rightLineRef, id) => {
        if (this.state.info) {
            this.close(infoRef, leftLineRef, rightLineRef);
            this.setState({ info: false });
        } else {
            this.open(infoRef, leftLineRef, rightLineRef, id);
            this.setState({ info: true });
        }
    };
    toggleGdpr = (infoRef, leftLineRef, rightLineRef, id) => {
        if (this.state.gdpr) {
            this.close(infoRef, leftLineRef, rightLineRef);
            this.setState({ gdpr: false });
        } else {
            this.open(infoRef, leftLineRef, rightLineRef, id);
            this.setState({ gdpr: true });
        }
    };
    open = (infoRef, leftLineRef, rightLineRef, id) => {
        const tl = new TimelineLite();
        TweenLite.to(leftLineRef.current, 0.3, {
            delay: 0.5,
            rotation: -45,
        });
        TweenLite.to(rightLineRef.current, 0.3, {
            delay: 0.5,
            rotation: 45,
        });

        tl.set(infoRef.current, {
            height: "auto",
            autoAlpha: 1,
            onComplete: () => {
                this.height = infoRef.current.getBoundingClientRect().height;
            },
        }).set(infoRef.current, {
            autoAlpha: 0,
            height: 0,
            onComplete: () =>
                TweenLite.to(infoRef.current, 0.3, {
                    autoAlpha: 1,
                    height: this.height,
                    onComplete: () => this.scrollTo(id),
                }),
        });
    };
    close = (infoRef, leftLineRef, rightLineRef) => {
        TweenLite.to(leftLineRef.current, 0.3, {
            delay: 0.5,
            rotation: 45,
        });
        TweenLite.to(rightLineRef.current, 0.3, {
            delay: 0.5,
            rotation: -45,
        });
        TweenLite.to(infoRef.current, 0.3, {
            autoAlpha: 0,
            height: 0,
        });
    };

    scrollTo = (id) => {
        TweenLite.to(window, 0.3, {
            delay: 0.2,
            scrollTo: { y: id, offsetY: -90, autoKill: false },
        });
    };

    openM3Web = () => window.open("https://m3studio.cz", "_blank");

    render() {
        return (
            <div className={styles.footer}>
                <img
                    onClick={this.openM3Web}
                    src={m3logo}
                    alt="logo"
                    className={styles.m3logo}
                />
                <Title
                    white
                    noMargin
                    id="informace"
                    text="Informace pro spotřebitele"
                />
                <DropdownSection subtitle="Informace o zpracování osobních údajů">
                    <div className={styles.rubric}>
                        <div className={styles.info}>
                            Informace o zpracování osobních údajů klientů
                            VL&Partneři s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf1)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o zpracování osobních údajů klientů VL
                            Reality s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf2)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o zpracování osobních údajů klientů VL
                            Consulting Office s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf3)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o zpracování osobních údajů klientů
                            Financial Office s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf4)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                    </div>
                </DropdownSection>

                <DropdownSection subtitle="Pojištění">
                    <div className={styles.rubric}>
                        <div className={styles.info}>
                            Informace o pojištění společnosti VL&Partneři s.r.o.
                            najdete{" "}
                            <span
                                onClick={() => window.open(pdf5)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o pojištění společnosti VL Reality s.r.o.
                            najdete{" "}
                            <span
                                onClick={() => window.open(pdf6)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o pojištění společnosti VL Consulting
                            Office s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf7)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                        <div className={styles.info}>
                            Informace o pojištění společnosti Financial Office
                            s.r.o. najdete{" "}
                            <span
                                onClick={() => window.open(pdf8)}
                                className={styles.link}
                            >
                                zde
                            </span>
                            .
                        </div>
                    </div>
                </DropdownSection>

                <DropdownSection subtitle="Informace o zprostředkovatelské činnosti">
                    <div className={styles.rubric}>
                        Spotřebitelské úvěry zprostředkováváme jako vázaný
                        zástupce Financial Office s.r.o. IČO: 098 20 001
                        (reklamní značka VL Consulting) zapsaný v registru ČNB a
                        zároveň registrovaný pod samostatným zprostředkovatelem
                        spotřebitelských úvěrů společnosti Partners Financial
                        Services, a.s. IČO: 276 99 781. V rámci této činnosti
                        neposkytujeme radu ve smyslu ustanovení par. 85 Zákona
                        č.257/2016 Sb. o Spotřebitelském úvěru.
                        <br />
                        <br />
                        Životního pojištění a pojištění majetku vykonáváme jako
                        vázaný zástupce Financial Office s.r.o. IČO: 098 20 001
                        (reklamní značka VL Consulting) samostatného
                        zprostředkovatele společnosti Partners Financial
                        Services, a.s. IČO: 276 99 781, se sídlem Türkova
                        2319/5b, 149 00 Praha 4 – Chodov.
                        <br />
                        <br />
                        Zprostředkování investičních, bankovních a penzijních
                        produktů vykonáváme jako vázaný zástupce Financial
                        Office s.r.o. IČO: 098 20 001 (reklamní značka VL
                        Consulting) investičního zprostředkovatele společnosti
                        Partners Financial Services, a.s. IČO: 276 99 781, se
                        sídlem Türkova 2319/5b, 149 00 Praha 4 – Chodov.
                        <br />
                        <br />
                        <div id="scroll" className={styles.more}>
                            Veškeré důležité dokumenty naleznete na stránkách{" "}
                            <a
                                href="https://www.partners.cz/dokumenty/
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.link}
                            >
                                https://www.partners.cz/dokumenty/
                            </a>
                        </div>
                    </div>
                </DropdownSection>
            </div>
        );
    }
}

export default Footer;
